import React, { Component, Fragment, useState, useEffect }  from 'react';
import axios from 'axios';

//import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import { makeStyles } from '@material-ui/core/styles';
import CheckButton from "react-validation/build/button";
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Icon from '@material-ui/core/Icon';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { format } from "date-fns";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
        KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import authHeader from '../services/auth-header';


const useStyles = makeStyles((theme) => ({
  
  button: {
    margin: theme.spacing(1),
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
	container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

function BasicDateTimePicker2(props) {
//  console.log('date func:',props.setDate);
	let newDate=null;
	if(props.setDate==null)
	{
		newDate = null;
	}else{
		newDate = new Date(props.setDate);
	}
	const [selectedDate, handleDateChange] = useState(newDate);
		//const setDate = props.setDate;
  		
	
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Date of Expiry:"
          format="yyyy-MM-dd"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
  );
}


function BasicDateTimePicker(props) {
 
   //const name = props.name;
	const checked = props.checked;
	const date = new Date();

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
	//new Date(year+1,month,day)
  //const [selectedDate, setSelectedDate] = useState(new Date(year+1,month,day));
const [selectedDate, setSelectedDate] = useState(null);

  const setDate = '2020-12-31'; 
  const classes = useStyles();
 
	console.log("date func: ",checked);
	
const handleDateChange = (date) => {
    console.log(date);
    setSelectedDate(date);
    console.log(selectedDate);
  };	
//className={classes.root}
  if(checked==true)
	{
	return (
	   
	 <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
          id={selectedDate}
	  margin="normal"
          label="Date of Expiry: " 
          format="yyyy-MM-dd"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }} />
	  </MuiPickersUtilsProvider>
 
  );
	}else{
return(<div> </div>);
	}


  //);
}

function IconLabelButtons(props) {
	const text = props.text;
  const classes = useStyles();

  return (
    <div>
      {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<Icon>send</Icon>}
      >
	  {text}
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        Save
</Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        Save submit
      </Button>
    </div>
  );
}

function getAllData(allData)
{
	//console.log("in func",allData);
	return allData.data.map((user, index) => {
                const { id, Name, SCusername } = user //destructuring
    //            console.log("life:");

//                console.log(id);
  //              console.log(Name);
      //          console.log(SCusername);
                return  (

          <MenuItem value={Name}>{Name}</MenuItem>
		)
	});
}


function renderTableDataRow2(allData,allStudies,name,checkedItems,handleChange) {
        const counter=0;
        return allData.data.map((user, index) => {
                const { study, id, Name, SCusername, lifetimes } = user //destructuring
                //console.log("life:");

                //console.log(user);
                //console.log(lifetimes.length);
         if(name==Name)
		{
		return  (
                <Fragment>
                        <Grid >

                        {
                                lifetimes.forEach(element => console.log(element))
                        }
                        {lifetimes.map((test,index) =>{
                  let studyName={};
                        allStudies.map((study,index) =>{
                        console.log("Studie: ",study.PackageName);
                        if(study.id==test.study)
                        {
                                studyName=study.PackageName;

                        }

                        })

				if(test.status==false){
                return( <Grid item xs>
                        <Switch color="primary"  inputProps={{ 'aria-label': 'disabled checkbox', 'color' : 'blue' }} label={study} />
                        < BasicDateTimePicker2 setDate={test.date}/>
                        </Grid>
                )       }
                else{
                return(
			<Grid>
            <FormControl component="fieldset" >
      <FormGroup>
                        <Checkbox
            key={studyName}
            name={studyName}
            checked={checkedItems[studyName]}
            onChange={handleChange}
        />            <BasicDateTimePicker2 setDate={test.date}/> 
      </FormGroup>
    </FormControl>
            </Grid>
                )

                }
               })}
                </Grid>
                </Fragment>
                )
	}

})
}

async function downloadFile(url,data,header) {
  await axios.put(url,data,header)
  .then(response => {
    console.log(response)
  })
  .catch(error => {
    console.log(error)
  })
}

function App()  {

	const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
		container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
  minWidth: 120,
  },
}));


	const classes = useStyles();
 const [selectedDate, setSelectedDate] = useState(new Date());
 const [checkedDates, setCheckedDates] = useState([]);
 const [checkedItems, setCheckedItems] = useState([]);
 const [message, setMessage] = useState(false);
const [scapi, setscapi] = useState('');
 const [currentUser,setcurrentUser] = useState('');
 const [hasError,seterror] =useState(false);
 const [allStudies,setAllStudies] = useState( [] );
 const modifiedData = useState({Name : '', SCusername: ''});
 const Tlifetimes = useState({id: [], date: '', mk_user: [], studies: [], study: []});
 const [fName, setfName] = useState({Name: '', SCusername: ''});	// State of your application
 const [lifetime, setLifetime] = useState([]);
 const [allData,setAllData] = useState([]);
const [studies,setStudies] = useState({Name: '', ID: false});
 const selDate = [];
 const selSwitch = [];
const selLifetime = [];
 
var obj = {};

 const [open, setOpen] = React.useState(false);
 const [username, setUsername] = React.useState('');
 const [name, setName] = React.useState('');	
 const [id, setID] = useState('');
 const [item, setItem] = React.useState('');

 const [state, setState] = React.useState({
	Basic: true,
	Advanced: true,
	Footprint: true,
 }
 );

const [stateTrue, setStateTrue] = React.useState({
        Basic: true,
        Advanced: true,
        Footprint: true,
 }
 );



  const handleChangeSwitch = (event) => {
    //setState({ ...state, [event.target.name]: event.target.checked });
//	  setStateTrue({ ...state, [event.target.name]: event.target.checked });
	  //setCheckedItems({ ...state, [event.target.name]: event.target.checked }); 
	  setCheckedItems(prev => ({
      ...prev,

        ...prev.setCheckedItems,
        [event.target.name]: event.target.checked,

   }));
	  //console.log('checked True State:',stateTrue);
	  //obj[event.target.name] = event.target.checked ;
  	//console.log('checked obj:',obj);
	  console.log('checked:',event.target.name,event.target.checked );
  };

  const handleChangeD = (event) => {
    console.log(event.target.value);
	  setName(event.target.value);
    //setName(name);
 	return allData.data.map((user, index) => {
         // 	setName(event.target.value);
		console.log('name:',name);
		const { id, Name, SCusername, lifetimes } = user //destructuring
                //console.log("life:");
               console.log('Name:',Name,'name:',name);
               // console.log(lifetime);
     		//	if(event.target.value==Name){
		if(event.target.value==Name){
				setID(id);		
				setUsername(SCusername);
				setOpen(true);
				setItem(event.target.value);
				//setItem(name);
			console.log('name:',name,'Name',Name);
	 	console.log(user);
                console.log(lifetimes);
		 allStudies.map((study,index) =>{
			//console.log('study:',study);
			lifetimes.map((life,index) =>{
		 	if(study.id==life.study)
			{
				//setLifetime(life);
				console.log('lifetimes:',life);
				console.log('study:',study.PackageName,life.status);
				//setItem(event.target.value);
				//setCheckedItems([study.PackageName]);
				selLifetime.push(life);

				setLifetime({

        life,

   });

				setCheckedItems(prev => ({
      ...prev,
     
        ...prev.setCheckedItems,
        [study.PackageName]: life.status,
      
   }));

			//	console.log('Items:',item);
			}

		 }
		 );
		 });

			/*		
			allStudies.map((test,index) =>{
			//console.log("Studies: ",test);
			

			})


			lifetimes.map((test,index) =>{
			console.log("lifi:",test.status)
			let studyName={};
			allStudies.map((study,index) =>{
                        console.log("Studie: ",study.PackageName);
			if(study.id==test.study)
			{ 
				studyName=study.PackageName;
				
			}

                        })
				if(test.status==false){
					setMessage(false);
				console.log("lifi false:",test.status);
				setCheckedItems({
      ...checkedItems,
      [studyName]: false
    });
					return(
						< BasicDateTimePicker2 setDate={test.date}/>
					)

				console.log("disable study: ",test.study,studyName,checkedItems);
			}else if(test.status==true)
				{
					setOpen(true);
				setMessage(true);
					console.log("enable study: ",test.study,studyName,checkedItems);
				setCheckedItems({
      ...checkedItems,
      [studyName]: true
    });
					return(
						<Grid item xs>
                                                < BasicDateTimePicker2 setDate={test.date}/>
                                        	</Grid>
					)
				}
				console.log("checka: ",checkedItems);
			})

*/
			/*	{lifetimes.map((test,index) =>{
                                if(test.status==false){
                return( <Grid item xs>
                        <Switch color="primary" disabled inputProps={{ 'aria-label': 'disabled checkbox', 'color' : 'blue' }} />
                        < BasicDateTimePicker setDate={test.date}/>
                        </Grid>
                )       }
                else{
                return( <Grid item xs>
                        <Switch disabled checked color="primary" inputProps={{ 'aria-label': 'primary checkbox' }}  />
      < BasicDateTimePicker setDate={test.date}/>
                        </Grid>
                )

                }
				 })} */
		//	)
		} 
        });
	
  };
	


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

useEffect(() => {

	  ( async () => {

    
	//const mkuser = await UserService.getUserBoard();
    const mkuser = await UserService.getUserBoard();
	if(mkuser){
    	setAllData(mkuser)
	    console.log('MKUSER:',mkuser);
	  }
    //const user = AuthService.getCurrentUser();
    //if (user) {
    //	setcurrentUser(user);
        //console.log(user);
   // }
	//	  let city = (new URLSearchParams(window.location.search)).get("name")
	//	  console.log('href',city);
//setName(city);		  
//handleChangeD;
  //};
try {
	const user = AuthService.getCurrentUser();
    if (user) {
        setcurrentUser(user);
        console.log("User:",user);
    }
         const response1 = await axios.get('https://license.quantx.gmbh/api/lifetimes',{ headers: authHeader() });  
         setLifetime(response1);
         console.log("Lifetimes:",response1);
    } catch {
      seterror(true);
    }

    try {
	 const response = await UserService.getStudies();
         setAllStudies(response.data);
         console.log('studies:',response.data);
    } catch {
      seterror(true);
    }
	 }) ()
  },[]);

const handleDateChange2 = (date) => {
    var formattedDate = format(date, "yyyy-MM-dd");

console.log(formattedDate);
    setSelectedDate(date);
    console.log(selectedDate);
  };	

const handleDateChange = (date) => {
    console.log("bla:",date);
    setSelectedDate(date);
    setCheckedDates(date);
	console.log("checkedDates:",checkedDates);
    console.log(selectedDate);
  };


const getDefaultCheckboxes = () =>
  allStudies.map(checkbox => ({
	  name: checkbox.PackageName,
    id: checkbox.id,	  
    checked: false,
  }));
	//console.log(getDefaultCheckboxes());
//id="date-picker-dialog"
////checked={checkedItems[study.PackageName]}
const createSwitch = data => {
                const { study, id, Name, SCusername, lifetimes } = data //destructuring
                //console.log(lifetimes.length);
         if(name==Name)
                {
			//ivar obj = {};
		console.log('ITEM:',checkedItems);
			console.log("study:",data);
   //             console.log('data switch name:',name);
//		console.log('data switch id:',id);
//                console.log('lifetimes switch:',lifetimes);
                return  (
                <Fragment>
                        <Grid >

                        {
		
				lifetimes.map((test,index) =>{
	
		if(test.mk_user==id)
					{
			//			var obj = {};
					let studyName={};
                        allStudies.map((study,index) =>{
                        if(study.id==test.study)
                        {
                                studyName=study.PackageName;

				console.log("Studie: ",study.PackageName,name,Name,id,test.id);
                        }

                        })

                                if(test.status==false){
				//console.log('false');
					obj[studyName] = false;
					//obj.studyName = false;
					const add=studyName+':false';
				selSwitch.push(add);
//				console.log('selSwitch:',obj,selSwitch);
				//console.log("state1: ",state);
				const newMessageObj = {  [studyName]: false };
          			//console.log("message: ",newMessageObj);
			//setStateTrue({ ...stateTrue,[studyName]: false });
					//		setState(newMessageObj);
//					console.log("state True: ",stateTrue);
					//setState([studyName]:false);
  //                      	console.log("item false:",studyName,state.[studyName]);
					return(
				 <Grid>
            <FormControl component="fieldset" >
      <FormGroup>
    <FormControlLabel
          control={<Switch key={studyName} checked={checkedItems.[studyName]}  onChange={handleChangeSwitch} name={studyName} />}
          label={studyName}
           />    
				<BasicDateTimePicker2 setDate={test.date}/>
      </FormGroup>
    </FormControl>
            </Grid>
			)
				}
                else if(test.status==true){
			//console.log('true');
			obj[studyName] = true;
			//console.log("item true:",checkedItems[studyName]);
//			console.log("state1: ",state);
                                const newMessageObj = {  [studyName]: true };
  //                              console.log("message: ",newMessageObj);
                                        //setState(newMessageObj);
			//setState({ ...state, [studyName]: true });
			////checked={state.[studyName]}
    //                                    console.log("state2: ",state);
                return(
                <Grid>
            <FormControl component="fieldset" >
      <FormGroup>
    <FormControlLabel
          control={<Switch key={studyName} checked={checkedItems.[studyName]} onChange={handleChangeSwitch} name={studyName} />}
          label={studyName}
           />
                                <BasicDateTimePicker2 setDate={test.date}/>
      </FormGroup>
    </FormControl>
            </Grid>
		)

                }
			//		}
					}		//	}
               })}
                </Grid>
                </Fragment>
                )
        }


}


const renderCheckbox = study =>
        {

                return(
        <Grid>
            <FormControl component="fieldset" className={classes.formControl}>
      <FormGroup>
                        <Checkbox
            key={study.id}
            name={study.PackageName}
            checked={checkedItems[study.PackageName]}
            onChange={handleChangeD}
        /> {message && (
                        <BasicDateTimePicker checked={checkedItems[study.PackageName]}/> )}
      </FormGroup>
    </FormControl>
            </Grid>

                )
        }

  const handleSubmit = async e => {
    e.preventDefault();
    //this.props.history.push('/add'); 
    console.log('sel state:',state);
	  console.log("data:",e);
	  console.log("data:",e.target[5].value);
	  console.log("data:",e.target[9].value);
    selDate.push(e.target[5].value);
    selDate.push(e.target[9].value);
    selDate.push(e.target[13].value);
    selDate.push(e.target[17].value);	 
	  console.log(selDate);
	  //const { modifiedData, Tlifetimes  } = this.state;
    const url = "https://www.sierrachart.com/API.php"; // site that doesn’t send Access-Control-*
    //const proxyurl = "https://license.quantx.gmbh/proxy/https://www.sierrachart.com/API.php";
    const proxyurl = "https://www.sierrachart.com/API.php";
/*
    var bodyFormData = new FormData();
    bodyFormData.append('AdminUsername', 'mkboersenhandel');
    bodyFormData.append('AdminPassword', 'MkBHanDeL2020100!*!');
    bodyFormData.append('Service', 'CustomDLLStudiesManagement');
    bodyFormData.append('SCDLLName', 'MKStudies');
    bodyFormData.append('Action', 'add');
    bodyFormData.append('UserSCUsername', fName.setfName.SCusername);
    bodyFormData.append('ServiceLevel', 5);
    bodyFormData.append('EndDate', ''); //2021/12/30
	
    const headers = {
    	'Content-Type': 'multipart/form-data',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,HEAD,OPTIONS,PATCH',
	    'withCredentials': false
	    
	    //'crossorigin' : true,
	//'': false
	    //'Authorization': 'JWT fefege...'
    }
	  
    await axios({
      method: 'post',
      url: proxyurl,
      data: bodyFormData,
	//mode: 'no-cors'    //,
	   headers: headers 
      })
      .then(function (response) {
          //handle success
          if(response.data.includes("SUCCESS"))
	  {
	      console.log("SCAPI SUCCESS:",response);
	  }else if(response.data.includes("already"))
	      {
		      setscapi('Already exist');
		      console.log("SCAPI already exist:",response);
	      }
	      else{
	      console.log("SCAPI not exist:",response);
	  }

      })
      .catch(function (response) {
          //handle error
          console.log("Error:",response);
      });
	
	{scapi && (
              
		<div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {scapi}
                </div>
              </div>
            )}
	   */

//      console.log(this.state.Tlifetimes);
	  //console.log('set: ',fName.setfName);
	 // const getUserInput = fName.setfName;
	  //console.log('userinput:',getUserInput);
    const getuserid=0;
	  //let sendData={};
      //const response = await axios.post(
       // 'https://license.quantx.gmbh/api/mk-users', fName.setfName,{ headers: authHeader() }
      //);
     Tlifetimes.user  = id;	     
      //console.log("resp:",Tlifetimes.user)
	//  console.log("Study ID:",allStudies.id)
	//	  console.log("resp2:",getuserid)
	  //console.log(checkedItems);
          //console.log(selectedDate);
	  //console.log("Lifetimes:",lifetime);
	  //console.log("allStudies:",allStudies);
	  //console.log('selLifetime:',selLifetime);
	  allData.data.map((user,index) =>
        {
		 const { id, Name, SCusername, lifetimes } = user //destructuring
	
		if(name==Name)
		{	
			
			console.log('SCusername:',username);
			console.log('checkedItems:',checkedItems);
			console.log('User.ID:'+Tlifetimes.user);	
			//console.log('allData: '+user);
			console.log('allData.Name: '+name);
			console.log('allData.lifetimes: '+lifetimes);
	

			 allStudies.map((study,index) =>{
                        var sendData={};
				 //console.log('study:',study);
                        lifetimes.map((life,index) =>{
                        if(study.id==life.study)
                        {
                                Tlifetimes.id = life.id;
				//setLifetime(life);
                                console.log('lifetimes:',life);
				console.log('lifetimes.id:',Tlifetimes.id);
                                console.log('study:',study.PackageName,life.status);
				
				 Object.entries(checkedItems).map(([key, value]) => {
				 	
					 
					//	console.log('item:',key,value);
			if(value==true && key==study.PackageName)
                  {
                          console.log("load lifetime true",Tlifetimes.id);

                       // async() => { await axios.get(link,{ headers: authHeader() });


                 //         console.log("concat",checkbox.PackageName);
               //           console.log("lifetime:",checkbox.lifetimes);

                          //if (checkbox.PackageName==key)
                  //{
                          const counter = study.id-1;
             //             console.log("counter:",counter);
                          if(selDate[study.id-1]=="")
                              Tlifetimes.date=null;//selDate[checkbox.id-1];
                          else
                                  Tlifetimes.date=selDate[study.id-1];
                          Tlifetimes.study = study.id;
                          //console.log("find date",Tlifetimes.date);
                          sendData = {"date": Tlifetimes.date, "mk_user.id": Tlifetimes.user, "study.id": Tlifetimes.study, "status": true};
               //         sendData = {date: Tlifetimes.date, mk_user: Tlifetimes.user, study: Tlifetimes.study, status: "true"};
                  }else if(value==false && key==study.PackageName){
                          console.log("load lifetime false",Tlifetimes.id);
                        Tlifetimes.date=null;
                          sendData = {"date": Tlifetimes.date, "mk_user.id": Tlifetimes.user, "study.id": study.id, "status": false};
                  }

					  
				});

			}
			});

			console.log("sendData: ",sendData);
           const linklife= 'https://license.quantx.gmbh/api/lifetimes/'+Tlifetimes.id;
                console.log("link:",linklife);
                const header=''
    downloadFile(linklife,sendData,{ headers: authHeader() });

			});


		}


	  });


	  window.alert(username+' successfully changed');
          window.location.reload(true);

  // this.props.dispatch({});
    
  };
const handleInputChange = ({ target: { name, value } }) => {
    console.log(value);
        setfName(prev => ({
      ...prev,
      setfName: {
        ...prev.setfName,
        [name]: value,
      },
   }));
  };

const handleInputChange2 = ({ target: { name, value } }) => {
    console.log(name, value);
        setLifetime(prev => ({
      ...prev,
      setLifetime: {
        ...prev.setLifetime,
        [name]: name,
      },
   }));
  const isChecked = false;
	console.log(lifetime);

  return (
          <FormControlLabel
          control={<Switch checked={isChecked}  name={name} />}
          label={name}
          />
  );  
};	

const handleChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
	if(event.target.checked ==true)
	{
	   setMessage(true);
	}
	
	if(event.target.checked == false)
        {
           setMessage(false);
        }
		console.log("checkedItems: ", checkedItems, event.target.value);
  };

const handleChange2 = event => {
    setCheckedDates({
      ...checkedDates,
      [event.target.name]: event.target.checked
    });
	return(
		<div> bla bal 
		<BasicDateTimePicker />
		</div>
	);
    console.log("checkedDates: ", checkedDates, event.target.value);
  };
  /* target.checked -> key
	const handleChange = event => {
    
    setLifetime(prev => ({
      ...prev,
      setLifetime: {
        ...prev.setLifetime,
        [event.target.name]: event.target.checked,
      },
   }));
	//};
    console.log("checkedItems: ", setLifetime);
  };
*/


const Checkbox = ({ type = "checkbox", name, checked=false , onChange }) => {
   //console.log("Checkbox: ", name, checked);

  if(checked==true)
  {
  //  setMessage(true);	   
    console.log("Checkbox: ", name, checked);
  //setStudies(name);
  }
	if(checked==false)
  {
//    setMessage(false);
    console.log("Checkbox: ", name, checked);
  //setStudies(name);
  }

	//<input type={type} name={name} checked={checked} onChange={onChange} />
  return (
	  <FormControlLabel
	  control={<Switch key={name} checked={checked} onChange={onChange} name={name} />}
          label={name}
	   />

  );
};

//  };


	//console.log(allStudies.map(this.renderCheckbox))

    return (
      <div className="col-md-12">
      <div className="card card-container">
        {currentUser && (
        <form onSubmit={handleSubmit}>
          <h3>Edit User</h3>
	
		<div>
                <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
	  onChange={handleChangeD}
        >
                {getAllData(allData)}
       </Select>
                </FormControl>
                </div>
		<div>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <TextField //id="input-with-icon-grid"
                name="Name"
                label="Name"
                InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
                ref={setfName.Name}
                value={name}
                onChange={handleInputChange}
                />
          </Grid>
                <Grid item>
            <TextField //id="input-with-icon-grid"
                name="SCusername"
                label="SCusername"
                InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
                ref={setfName.SCusername}
                value={username}
		onChange={handleInputChange}		
                />
          </Grid>
		</Grid>

		<h4>Select Study</h4>
		<Grid item>
		 <div> {allData.data.map(createSwitch)}  </div> 
	</Grid>
		</div>       
	



          <div className="form-group">  
          <Button 
            variant="contained"
		color="primary"
	    className="btn btn-primary btn-block" 
            type="submit">
             Save
            </Button>
            </div>
		</form>

	)}
 


      </div>

	    </div>

    );
  }

//<IconLabelButtons text="speicher"/>


export default App;
