import React, { Component, Fragment, useState } from "react";
import ReactDOM from 'react-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { DataGrid, RowsProp, ColDef } from '@material-ui/data-grid';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import { DatePicker } from '@material-ui/pickers';
//import DateFnsUtils from '@date-io/date-fns';
//import ScheduleSelector from 'react-schedule-selector'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
	KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Moment from 'moment';
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
//import datePicker from "./datepicker.js";

function test(t) {      //defining a function
  if (t === undefined) {       //if t=undefined, call tt
        console.log(t)      //call t
  }
  return t;
}

function BasicDateTimePicker(props) {
	let newDate=null;
        if(props.setDate==null)
        {
                newDate = null;
        }else{
                newDate = new Date(props.setDate);
        }
        const [selectedDate, handleDateChange] = useState(newDate);
  //const [selectedDate, handleDateChange] = useState(new Date(props.setDate));
  //const setDate = props.setDate;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
	  <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Date of Expiry:"
          format="yyyy-MM-dd"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </MuiPickersUtilsProvider>
  );
}

export default class BoardUser extends Component {
  //const [blogs, setBlogs] = useState([]);
	constructor(props) {
	    super(props);
    
   this.state = {
      content: "",
      allData: [],
      lifetimes: [],
      studies: [],
      name: [],
      id: [],
      schedule : [],
      selectedDate: new Date(),
	   open: false,
	   setClose: false,
	
   };

  };

	componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
       // showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
       // showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    UserService.getUserBoard().then(
      response => {
        this.setState({
          allData: response.data,
	  name: response.data.Name,
          id: response.data.id
        });
        
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    UserService.getLifetimes().then(
      response => {
        this.setState({
          lifetimes: response.data,
        });

      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    UserService.getStudies().then(
      response => {
        this.setState({
          studies: response.data,
        });
//	      console.log("studies:",this.state.studies);

      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );		
    /*renderTableData() {
	      return this.state.allData.map((user, index) => {
	              const { id, Name, SCusername } = user //destructuring
	               return (
	                   <tr key={id}>
                      <td>{id}</td>
                      <td>{Name}</td>
                      <td>{SCusername}</td>
                     </tr>
                     );
            });
	}*/
  }


renderTableHeader() {
      //let header = Object.keys(this.state.allData[0])
      //return header.map((key, index) => {
      //   return <th key={index}>{key.toUpperCase()}</th>
     // }
   }

renderTableData() {
	return this.state.allData.map((user, index) => {
        	const { id, Name, SCusername } = user //destructuring
                return (
	          <tr key={id}>
                      <td>{id}</td>
                      <td>{Name}</td>
                      <td>{SCusername}</td>
                  </tr>
                )
             })
}


doSomethingWithInput(event) {
    event.preventDefault();
    const {
        textFieldInput,
      } = this.state;
    // Use textFieldInputi
//	console.log("edit: ",event);
  }


handleClose() {
this.setState({ open: false });
//	console.log("close button / dialog");
}

handleOpen() {
this.setState({ open: true });
//      console.log("close button / dialog");
}

openDialog() {
    
return(
<Dialog
        open={this.state.open}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit user settings"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          User: {this.state.name} will bearbeitet werden :)
                        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
            Exit
          </Button>
        </DialogActions>
      </Dialog>
);
}

openFunc(value) {
//	this.state.open=true;
  this.setState({
     open_dialog: true,
     myid: value
  });
//	console.log("edit: ",value,this.state.open);
return( <Grid item xs>
                testi sa121234234v ########        
		</Grid>
                )
}
renderTableDataRow() {
        return this.state.allData.map((user, index) => {
                const { id, Name, SCusername, lifetimes } = user //destructuring
		return  { id: id, firstName: Name, basic: <BasicDateTimePicker />, volume: lifetimes[1].date, footprint: lifetimes[2].date } 
             })
}
//setDate={lifetimes.[0].date}  < BasicDateTimePicker setDate={test.date}/>
renderTableDataRow2() {
	const counter=0;
        return this.state.allData.map((user, index) => {
                const { study, id, Name, SCusername, lifetimes, created_at } = user //destructuring
                //console.log("life:");
		
		//console.log(lifetimes);
		//console.log(this.state.studies);
		//console.log(lifetimes.length);
		return  ( 
		<Fragment>
			<Grid container wrap="nowrap" spacing={5}>
		 
			<Grid item xs>
		  {Name}    
             	  </Grid>
		<Grid item xs>
                  {SCusername} 
                  </Grid>
			{this.state.studies.map((studie,index) => { 
				return(lifetimes.map((test,index) =>{
					//console.log('life.id:',test.study,' studie.id:',studie.id,' status:',test.status);
				if(test.study==studie.id){
				//console.log('gleich');
					let setDate='';
				if(test.date==null)
				{
					setDate='unlimited';
				}
				else{
					setDate=test.date;
				}
				if(test.status==false){
				//	console.log('draw');

		return(<Grid item xs>
		<Switch color="primary" disabled inputProps={{ 'aria-label': 'disabled checkbox', 'color' : 'blue' }} /> disabled
		</Grid>
		)	}
		else{
		return(<Grid item xs>
			<Switch disabled checked color="primary" inputProps={{ 'aria-label': 'primary checkbox' }}  /> {setDate} 
			</Grid>
                )

		}}
	       }))}
			)}
			<Grid item xs>
			{Moment(created_at).format('YYYY-MM-DD')}
			</Grid>
		
		<Grid item xs>	
			 <Button variant="outlined" color="primary" onClick={(e) => {
      e.preventDefault();
      window.location.href='https://license.quantx.gmbh/edit/?name='+Name;
      }}>
        Edit
      </Button>
			</Grid>
			</Grid>
		</Fragment>
			
)})
}


handleDateChange = date => {
    this.setState({ selectedDate: date })
  }

  render() {
    const { studies, selectedDate, id, name, lifetimes, allData, content, currentUser } = this.state;
    const  data  = content;
//	  console.log(allData);
  //  console.log(lifetimes);	 
    //console.log(this.renderTableDataRow());



    const columns = [ 
	    { field: 'id', headerName: 'ID', width: 70},
	    { field: 'firstName', headerName: 'Name', width: 230 }, 
    	    { field: 'basic', headerName: 'Basic', width: 120 },
	    { field: 'volume', headerName: 'Volume', width: 120 },
	    { field: 'footprint', headerName: 'Footprint', width: 120},
	    { field: 'tail', headerName: 'Tail', width: 120}
    ];		  
    //const rows = [ {id: id, firstName: name }];
    const rows = [];
    return (
   <div>   
	  <Card>
	    <CardContent>
 	 <Fragment>
            <Grid container wrap="nowrap" spacing={6}>
	    <Grid item xs>
	   <strong>Name</strong>
	    </Grid>
	  <Grid item xs>
            <strong>SCUsername</strong>
           </Grid>
	   <Grid item xs>
           <strong>Basic</strong>
           </Grid>
	   <Grid item xs>
            <strong>Advanced</strong>
           </Grid>
	    <Grid item xs>
            <strong>Footprint</strong>
            </Grid>
	    <Grid item xs>
            <strong>Tail</strong>
            </Grid>
	    <Grid item xs>
            <strong>Created</strong>
            </Grid>
	    <Grid item xs>
            <strong>Edit</strong>
            </Grid>
	    </Grid>
	    </Fragment>
	    <Typography variant="subtitle1" gutterBottom>
      </Typography> 
	{this.renderTableDataRow2()}
	</CardContent>
	    </Card>
</div>
    );
}
}
