import React, { Component, Fragment, useState, useEffect }  from 'react';
import axios from 'axios';

//import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import { makeStyles } from '@material-ui/core/styles';
import CheckButton from "react-validation/build/button";
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Icon from '@material-ui/core/Icon';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { format } from "date-fns";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
        KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import authHeader from '../services/auth-header';
import { useForm } from "react-hook-form";


const useStyles = makeStyles((theme) => ({
  
  button: {
    margin: theme.spacing(1),
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
	container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

function BasicDateTimePicker(props) {
 
   //const name = props.name;
	const checked = props.checked;
	const date = new Date();

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
	//new Date(year+1,month,day)
  //const [selectedDate, setSelectedDate] = useState(new Date(year+1,month,day));
const [selectedDate, setSelectedDate] = useState(null);

  const setDate = '2020-12-31'; 
  const classes = useStyles();
 
//	console.log("date func: ",checked);
	
const handleDateChange = (date) => {
    //console.log(date);
    setSelectedDate(date);
   // console.log(selectedDate);
  };	
//className={classes.root}
  if(checked)
	{
	return (
	   
	 <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
          id={selectedDate}
	  margin="normal"
          label="Date of Expiry: " 
          format="yyyy-MM-dd"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }} />
	  </MuiPickersUtilsProvider>
 
  );
	}else{
return(<div> </div>);
	}


  //);
}

function IconLabelButtons(props) {
	const text = props.text;
  const classes = useStyles();

  return (
    <div>
      {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<Icon>send</Icon>}
      >
	  {text}
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        Save
</Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        Save submit
      </Button>
    </div>
  );
}

async function downloadFile(url,data,header) {
  await axios.post(url,data,header)
  .then(response => {
    console.log(response)
  //window.alert(response);
  })
  .catch(error => {
    console.log(error)
  })
}

function App()  {

	const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));
	const classes = useStyles();
 //const [selectedDate, setSelectedDate] = useState(null)
	const [selectedDate, setSelectedDate] = useState(new Date());
 const [checkedDates, setCheckedDates] = useState([]);
 const [checkedItems, setCheckedItems] = useState([]);

 const [message, setMessage] = useState(false);
const [scapi, setscapi] = useState('');
 const [currentUser,setcurrentUser] = useState('');
 const [hasError,seterror] =useState(false);
 const [allStudies,setAllStudies] = useState( [] );
 const modifiedData = useState({Name : '', SCusername: ''});
 const Tlifetimes = useState({date: '', mk_user: [], studies: [], study: []});
 const [fName, setfName] = useState({Name: '', SCusername: ''});	// State of your application
 const [lifetime, setLifetime] = useState([]);
 const [studies,setStudies] = useState({Name: '', ID: false});
 const selDate = []; 
 const { register, handleSubmit, setError, errors, reset } = useForm();
 let scError=false;
useEffect(() => {

	  ( async () => {

      
    const user = AuthService.getCurrentUser();
    if (user) {
    	setcurrentUser(user);
        //console.log(user);
    }

    try {
         const response1 = await axios.get('https://license.quantx.gmbh/api/lifetimes',{ headers: authHeader() });
         setLifetime(response1.data);
        // console.log(lifetime);
    } catch {
      seterror(true);
    }

    try {
	 const response = await UserService.getStudies();
         setAllStudies(response.data);
         //console.log(allStudies);
//	    getDefaultCheckboxes();
    } catch {
      seterror(true);
    }
		  //getDefaultCheckboxes();

		  
	  })()
  },[]);

const handleChangeSwitch = (event) => {
    //setState({ ...state, [event.target.name]: event.target.checked });
//        setStateTrue({ ...state, [event.target.name]: event.target.checked });
          //setCheckedItems({ ...state, [event.target.name]: event.target.checked });
          setCheckedItems(prev => ({
      ...prev,

        ...prev.setCheckedItems,
        [event.target.name]: event.target.checked,

   }));

	if(event.target.checked ==true)
        {
           setMessage(true);
        }

        if(event.target.checked == false)
        {
           setMessage(false);
        }
          //console.log('checked True State:',stateTrue);
          //obj[event.target.name] = event.target.checked ;
        //console.log('checked obj:',obj);
          //console.log('checked:',event.target.name,event.target.checked );
  };

const handleDateChange2 = (date) => {
    var formattedDate = format(date, "yyyy-MM-dd");

console.log(formattedDate);
    setSelectedDate(date);
    console.log(selectedDate);
  };	

const handleDateChange = (date) => {
    console.log("bla:",date);
    setSelectedDate(date);
    setCheckedDates(date);
	console.log("checkedDates:",checkedDates);
    console.log(selectedDate);
  };

/*
const getDefaultCheckboxes = () =>
  console.log('inside');
	allStudies.map((checkbox,index) => {
  	console.log('inside2',checkbox.PackageName);
		setCheckedItems(prev => ({
      ...prev,

        ...prev.setCheckedItems,
        [checkbox.PackageName]: false,

   }));
  });
*/	//console.log(getDefaultCheckboxes());
//id="date-picker-dialog"
//{message && (
//                        <BasicDateTimePicker checked={checkedItems[study.PackageName]}/> )}

const renderCheckbox = study =>
{
	return(
        <Grid>
            <FormControl component="fieldset" className={classes.formControl}>
      <FormGroup>
			<Checkbox
            key={study.id}
	    name={study.PackageName}
            checked={checkedItems[study.PackageName]}
            onChange={handleChangeSwitch}
        /> {message &&  (
		<BasicDateTimePicker checked={checkedItems[study.PackageName],selectedDate,setSelectedDate}/>)} 
      </FormGroup>			
    </FormControl>
            </Grid>

		)
	}

//handleSubmit async e
  const onSubmit = async (data, e) => {
    e.preventDefault();
    //this.props.history.push('/add');
//    console.log('data:',data);
    console.log('e:',e);
//	  console.log("data:",e.target[4].value);
//	  console.log("data:",e.target[8].value);
//	  console.log("data:",e.target[12].value);
    selDate.push(e.target[4].value);
    selDate.push(e.target[8].value);
    selDate.push(e.target[12].value);
    selDate.push(e.target[16].value);	
	  //
	  ///console.log(selDate);
	  //const { modifiedData, Tlifetimes  } = this.state;
    const url = "https://www.sierrachart.com/API.php"; // site that doesn’t send Access-Control-*
    //const proxyurl = "http://127.0.0.1:8080/https://www.sierrachart.com/API.php";
    const proxyurl = "https://www.sierrachart.com/API.php";

    var bodyFormData = new FormData();
    bodyFormData.append('AdminUsername', 'mkboersenhandel');
    bodyFormData.append('AdminPassword', 'MkBHanDeL2020100!*!');
    bodyFormData.append('Service', 'CustomDLLStudiesManagement');
    bodyFormData.append('SCDLLName', 'MKStudies');
    bodyFormData.append('Action', 'add');
    bodyFormData.append('UserSCUsername', fName.setfName.SCusername);
    bodyFormData.append('ServiceLevel', 5);
    bodyFormData.append('EndDate', ''); //2021/12/30
	
    const headers = {
    	'Content-Type': 'multipart/form-data',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,HEAD,OPTIONS,PATCH',
	    'withCredentials': false
	    
	    //'crossorigin' : true,
	//'': false
	    //'Authorization': 'JWT fefege...'
    }
	  console.log('hasError: ',scError);

//await -> in external function	  
    await axios({
      method: 'post',
      url: proxyurl,
      data: bodyFormData,
	//mode: 'no-cors'    //,
	   headers: headers 
      })
      .then(function (response) {
          //handle success
          if(response.data.includes("SUCCESS"))
	  {
	      console.log("SCAPI SUCCESS:",response);
	  }else if(response.data.includes("already"))
	      {
		      scError=true;
		      setscapi('Sierra API: '+fName.setfName.SCusername+' already exist');
		      console.log("SCAPI already exist:",response);
	      		seterror(true);
	      }
	      else{
		      scError=true;
		      setscapi('Sierra API: '+fName.setfName.SCusername+' does not exist');
	      console.log("SCAPI not exist:",response);
	  }

      })
      .catch(function (response) {
          //handle error
          scError=true;
	      console.log("Error:",response);
      });

		
	  if(scError==true)
	  {
		  console.log('hasError: ',scError);
		  e.target.reset();
		  //window.alert(scapi);
	  }

	  if(scError==false)
	  {
		//e.preventDefault();
	  
	{scapi && (
              
		<div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {scapi}
                </div>
              </div>
            )}
	   
//      console.log(this.state.Tlifetimes);
      //console.log('fName ',fName);
	//  console.log('set: ',fName.setfName);
	  const getUserInput = fName.setfName;
	  //console.log('userinput:',getUserInput);
    const getuserid=0;
	  //let sendData={};
	console.log('user post: ',fName.Name);
		  console.log('user post: ',fName.setfName);
		  //await 21.01
      const response =  await axios.post(
        'https://license.quantx.gmbh/api/mk-users', fName.setfName,{ headers: authHeader() }
      ).then(response => {
          console.log('status response:',response.data);
      	Tlifetimes.user  = response.data.id;  
      })
        .catch(err => {
		console.log('Error1:',err);
		
		
			setscapi('API: '+fName.setfName.Name+' already exist');
			console.log('Error2:',err);
        	
	});

     //Tlifetimes.user  = response.data.id;	     
      console.log("resp id:",Tlifetimes.user)
	//  console.log("Study ID:",allStudies.id)
	//	  console.log("resp2:",getuserid)
	  //console.log(checkedItems);
          //console.log(selectedDate);
	  //console.log('Array Date:',selDate);
allStudies.map((checkbox) => {
	var sendData={};
	const linklife= 'https://license.quantx.gmbh/api/lifetimes/';
//	  for (let key in allStudies) {
  		console.log("Object: ",Object.keys(checkedItems));
	//	  console.log('user',Tlifetimes.user);
		console.log("study id for:",checkbox.PackageName);
		  //console.log(allStudies[2].id);
		
		  //allStudies.map(checkbox => {
		  
		  if(Object.keys(checkedItems).includes(checkbox.PackageName))
		  {
			  console.log("concat",checkbox.PackageName," true");
//		          console.log("checkbox:",checkbox);		 

			  //if (checkbox.PackageName==key)
	          //{
			  const counter = checkbox.id-1;
//			  console.log("counter:",counter);
			  if(selDate[checkbox.id-1]=="")
			  	Tlifetimes.date=null;//selDate[checkbox.id-1];  
		  	  else
				  Tlifetimes.date=selDate[checkbox.id-1];
			  Tlifetimes.study = checkbox.id;
//			  console.log("find id",checkbox.id)
		  	sendData = {date: Tlifetimes.date, mk_user: Tlifetimes.user, study: Tlifetimes.study, status: "true"};
		  	axios.post(linklife,sendData,{ headers: authHeader() });
			  //downloadFile(linklife,sendData,{ headers: authHeader() });
		  }else{
			Tlifetimes.date=null;
			  sendData = {date: Tlifetimes.date, mk_user: Tlifetimes.user, study: checkbox.id, status: "false"};
		  	axios.post(linklife,sendData,{ headers: authHeader() });
			  console.log("concat",checkbox.PackageName," false");
			  //downloadFile(linklife,sendData,{ headers: authHeader() });
		  }
//		  });

		  //Tlifetimes.study = key;
	//	console.log(Tlifetimesi)
		  //;
		 // const sendData = {date: Tlifetimes.date, mk_user: Tlifetimes.user, study: Tlifetimes.study, status: "true"};
/*
		  console.log(checkbox.PackageName,':',sendData);
	const linklife= 'https://license.quantx.gmbh/api/lifetimes/';

	const response2 =  axios.post(
        linklife,sendData,{ headers: authHeader() }
	).then(response2 => {
          console.log('status response:',response2.data);
        //Tlifetimes.user  = response.data.id;
      })
        .catch(err => {
                console.log('Error1:',err);


          //              setscapi('API: '+fName.setfName.Name+' already exist');
            //            console.log('Error2:',err);

        });
*/	
	//downloadFile(linklife,sendData,{ headers: authHeader() });   
	/*
		  try {
      console.log("loading");
		  const response = axios.post(
       	'https://license.quantx.gmbh/api/lifetimes', sendData,{ headers: authHeader() }
      )
                  console.log(response.data)
	
			  
			  ;}
      catch	
		  {

seterror(true);		  } */
	 // }

	  

	  });

//	console.log('dates last:',selectedDate);
	  //e.target.reset(setCheckedItems({Basic: false, Advanced: false, Footprint: false}));
	  window.alert(fName.setfName.SCusername+' successfully added');
          window.location.reload(true);
	  // this.props.dispatch({});
	  }
	  //window.alert(fName.setfName.SCusername+' successfully added');
  };
const handleInputChange = ({ target: { name, value } }) => {
    //console.log('name:',value);
	//getDefaultCheckboxes();
        setfName(prev => ({
      ...prev,
      setfName: {
        ...prev.setfName,
        [name]: value,
      },
   }));
  };

const handleInputChange2 = ({ target: { name, value } }) => {
    


//	console.log(name, value);
        setLifetime(prev => ({
      ...prev,
      setLifetime: {
        ...prev.setLifetime,
        [name]: name,
      },
   }));
  const isChecked = false;
//	console.log(lifetime);

  return (
          <FormControlLabel
          control={<Switch checked={isChecked}  name={name} />}
          label={name}
          />
  );  
};	

const handleChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
	if(event.target.checked ==true)
	{
	   setMessage(true);
	}
	
	if(event.target.checked == false)
        {
           setMessage(false);
        }
//		console.log("checkedItems: ", checkedItems, event.target.value);
  };

const handleChange2 = event => {
    setCheckedDates({
      ...checkedDates,
      [event.target.name]: event.target.checked
    });
	return(
		<div> bla bal 
		<BasicDateTimePicker />
		</div>
	);
  //  console.log("checkedDates: ", checkedDates, event.target.value);
  };
  /* target.checked -> key
	const handleChange = event => {
    
    setLifetime(prev => ({
      ...prev,
      setLifetime: {
        ...prev.setLifetime,
        [event.target.name]: event.target.checked,
      },
   }));
	//};
    console.log("checkedItems: ", setLifetime);
  };
*/


const Checkbox = ({ type = "checkbox", name, checked=false , onChange }) => {
   //console.log("Checkbox: ", name, checked);

/* if(checked==true)
  {
   setMessage(true);	   
 //   console.log("Checkbox: ", name, checked);
  //setStudies(name);
  }
	if(checked==false)
  {
    setMessage(false);
    //console.log("Checkbox: ", name, checked);
  //setStudies(name);
  }
*/

	//<input type={type} name={name} checked={checked} onChange={onChange} />
  return (
	  <FormControlLabel
	  control={<Switch key={name} checked={checked} onChange={onChange} name={name} />}
          label={name}
	   />

  );
};

//  };


	//console.log(allStudies.map(this.renderCheckbox))

    return (
      <div className="col-md-12">
      <div className="card card-container">
        {currentUser && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>Study User</h3>

	<div>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <TextField //id="input-with-icon-grid" 
		name="Name"
		label="Name" 
		InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}    
		ref={setfName.Name}
		onChange={handleInputChange}
                //value={modifiedData.name}

		/>
          </Grid>
		<Grid item>
            <TextField //id="input-with-icon-grid"
                name="SCusername"
                label="SCusername"
                InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
                ref={setfName.SCusername}
                onChange={handleInputChange}
                //value={modifiedData.name}

                />
          </Grid>
         <h4>Select Study</h4> 
	<Grid item>
	{allStudies.map(renderCheckbox)} 
	</Grid>
</Grid>
      </div>       

		{scapi && (

                <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {scapi}
                </div>

			<Button
            variant="contained"
                color="secondary"
            className="btn btn-primary btn-block"
            onClick={() => { window.location.reload(true)}}
	    type="submit">
             New
            </Button>
              </div>
            )}
          <div className="form-group">  
          <Button 
            variant="contained"
		color="primary"
	    className="btn btn-primary btn-block" 
            type="submit">
             Save
            </Button>
            </div>

        </form>)}

            

      </div>

	    </div>

    );
  }

//<IconLabelButtons text="speicher"/>


export default App;
